<template>
    <div class="MediaEdit full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('general.name') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="editMedia-name" @onInputFocus="onInputFocus" :readonly="(!editable && this.editMedia.id != null) || !checkUserRights('mediaEdit')" :class="{'noAction': !editable || !checkUserRights('mediaEdit')}" :rules="[v => !!v || $t('validation.required'), rules.alphanumericSpecCharName, rules.maxLengthName]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMedia-status" :disabled="!checkUserRights('mediaEdit')" :class="{'active': status}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-6">
                            <p>{{ $t('general.status') }}</p>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="delete" :class="{'disabled': !editable || editMedia.id == null || !checkUserRights('mediaDelete')}" @footerButtonClick="deleteMedia"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToMedia"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('mediaEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'

import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'MediaEdit',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            originalMedia: {},
            rules: {
                alphanumericSpecCharName: v => {
                    if (v) {
                        let alphanumericSpecChar = /^[ A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidName')}`;
                    } else return true;
                },
                maxLengthName: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'medialist',
            'editMedia'
        ]),
        status(){
            return this.$store.state.editMedia.status;
        },
        editable(){
            return this.editMedia.editable;
        },
    },
    methods:{
        goToMedia(){
            this.$router.push('media');
        },
        deleteMedia(){
            this.$store.dispatch('postAxiosNoSetter', ['/disi/media/delete', {'id': parseInt(this.editMedia.id)}])
            .then(response=>{
                if (response.status === 200){
                    this.goToMedia();
                }
            })
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()){
                this.showSaveProgress = true;
                if (this.editMedia.id != null){
                    let Media = {}
                    //optional
                    if (this.editMedia.name != this.originalMedia.name && this.editMedia.name != ""){
                        Media.name = this.editMedia.name;
                    }
                    if (this.editMedia.status != this.originalMedia.status){
                        Media.status = this.editMedia.status;
                    }
                    if (Object.keys(Media).length === 0 && Media.constructor === Object){
                        this.showSaveProgress = false;
                        this.showSaveSuccess = true;
                        this.watcher();
                        setTimeout(()=>{
                            this.saveDisabled = true;
                            this.showSaveSuccess = false;
                        }, 800)
                    } else {
                        //must send
                        Media.id = parseInt(this.editMedia.id);
                        this.$store.dispatch('postAxiosNoSetter', ['disi/media/edit', Media])
                        .then(response=>{
                            this.showSaveProgress = false;
                            if (response.status === 200){
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(()=>{
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                    }
                } else {
                    let Media = {
                        //must send
                        "name": this.editMedia.name,
                        "status": this.editMedia.status
                    }
                    this.$store.dispatch('postAxiosNoSetter', ['disi/media/new', Media])
                    .then(response=>{
                        this.showSaveProgress = false;
                        if (response.status === 200){
                            this.editMedia.id = response.data.id
                            this.showSaveSuccess = true;
                            this.watcher();
                            setTimeout(()=>{
                                this.saveDisabled = true;
                                this.showSaveSuccess = false;
                            }, 800)
                        }
                    })
                }
            }
        },
        watcher(){
            //copy editMedia to originalMedia for comparison
            this.originalMedia = JSON.parse(JSON.stringify(this.editMedia));

            let name = this.$watch('$store.state.editMedia.name', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let status = this.$watch('$store.state.editMedia.status', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function(){
                name();
                status();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$emit("resetInput");
        this.watcher();
    },
    beforeRouteLeave (to, from, next) {
        if(this.saveDisabled){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>